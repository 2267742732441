<template>
    <div class="textarea__wrapper">
        <textarea
            :class="['textarea__field', isClassName]"
            :id="idField"
            :name="isName"
            :required="isRequired"
            :maxlength="isMaxLength"
            :placeholder="isPlaceholder"
            :value="valueField"
            @input="onTextArea"
        ></textarea>

        <div class="textarea__counter">
            {{ valueField.length }} / {{ isMaxLength }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'vTextArea',
        data() {
            return {
                idField: this.isId || `v-textarea-${this._uid}`,
                valueField: this.modelValue || ''
            };
        },
        props: {
            isId: {
                type: String,
                default: ''
            },

            isName: {
                type: String,
                default: 'v-textarea'
            },

            isRequired: {
                type: Boolean,
                default: false
            },

            isPlaceholder: {
                type: String,
                default: ''
            },

            isMaxLength: {
                type: Number,
                default: 100
            },

            isClassName: {
                type: String,
                default: ''
            },

            modelValue: {
                type: String,
                default: ''
            }
        },
        methods: {
            onTextArea(event) {
                const target = event.target;

                this.valueField = target.value;
                this.$emit('input', target.value);
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '#sass/_variables.sass';
    @import "#sass/v-style";

    .textarea {
        &__wrapper {
            display: flex;
            flex-direction: column;
        }

        &__field {
            display: block;
            width: 100%;
            min-height: 150px;
            padding: 6px 11px;
            outline: 0;
            resize: vertical;
            border-radius: 2px;
            border-color: #d5dbe4;

            font-size: rem(14);
            font-weight: 600;
            line-height: 1.57;

            transition: border-color 0.3s;

            @include below($mobile) {
                font-size: 14px;
            }

            &:hover,
            &:focus {
                border-color: #959595;
            }
        }

        &__counter {
            font-size: 10px;
            text-align: right;
            color: $text-darkgray-3;
            margin: 5px 0px 0px;
        }
    }
</style>
